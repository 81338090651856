const service = {};

service.trackEvent = function (name, params) {
  window.gtag('event', name, params);
};

service.trackLead = function () {
  window.gtag('event', 'conversion', {
    send_to: 'AW-473233323/7TaCCM_ZkPYBEKvv0-EB',
    event_callback: () => {
      console.log('Lead tracked');
    },
  });
};

export default service;
