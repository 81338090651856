<template>
  <div class="home">
    <div ref="typeform" class="typeform"></div>
  </div>
</template>

<script>
import $analytics from '@/services/analytics';

export default {
  name: 'Home',
  data() {
    return {
      typeform: null,
    };
  },
  mounted() {
    window.typeformEmbed.makeWidget(this.$refs.typeform, 'https://csuitecircle.typeform.com/to/myapNlev', {
      onSubmit: (data) => {
        console.log('Submitted', data);
        $analytics.trackLead();
      },
    });
    $analytics.trackEvent('loaded');
  },
};
</script>
<style scoped>
  .typeform
  {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
  .button
  {
    display:inline-block;
    text-decoration:none;
    background-color:#3A7685;
    color:white;
    cursor:pointer;
    font-family:Helvetica,Arial,sans-serif;
    font-size:25px;
    line-height:62.5px;
    text-align:center;
    margin:0;
    height:62.5px;
    padding:0px 41px;
    border-radius:31px;
    max-width:100%;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    font-weight:bold;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
  }
</style>
